import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Button } from './elements/button'
import { Icon } from '../icons/icon'

const isDev = process.env.NODE_ENV === 'development'

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  }
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {},
    }
    this.handleClick = this.handleClick.bind(this)
  }

  next = (values) =>
    this.setState((state) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }))

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }))

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      return onSubmit(values)
    } else {
      this.next(values)
      // Scroll
      const section = document.querySelector('#start-form')
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  handleClick = () => {
    const { page } = this.state
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'new_step',
      page: page,
    })
  }

  render() {
    const { children } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1
    return (
      <Form initialValues={values} validate={this.validate} onSubmit={this.handleSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div className="p-b-sm-fluid" />
            <div>
              {/* {page > 0 && (
                <button className="mt-8 text-left" type="button" onClick={this.previous}>
                  <Icon name="arrow-left" />
                  Previous
                </button>
              )} */}
              {!isLastPage && (
                <Button type="submit" onClick={this.handleClick}>
                  Next
                  <Icon name="arrow-right" className="ml-4 h-8 w-8" />
                </Button>
              )}
              {isLastPage && (
                <Button disabled={submitting} className="mt-8">
                  Submit
                </Button>
              )}
            </div>

            {isDev && (
              <pre className="mt-16 bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">{JSON.stringify(values, 0, 2)}</div>
              </pre>
            )}
          </form>
        )}
      </Form>
    )
  }
}
