import React, { MouseEvent, TouchEvent } from 'react'

export enum ButtonSizes {
  default = 'default',
  lg = 'lg',
  xl = 'xl',
  xs = 'xs',
}

const buttonsizes = {
  default: `py-4 px-4`,
  lg: `py-6 px-6`,
  xl: `py-6 px-6 text-xl`,
  xs: `py-2 px-4`,
}

export enum ButtonStyles {
  default = 'default',
  ghost = 'ghost',
}

const styles = {
  default: `text-white bg-black hover:text-black hover:bg-white border-2 border-black focus:outline-none focus:shadow-outline focus:border-black focus:shadow-outline-black`,
  ghost: `text-goldig-700 bg-default border hover:text-goldig-500 border-goldig-300 focus:outline-none focus:shadow-outline focus:border-goldig-300 focus:shadow-outline-green`,
}

interface ButtonProps {
  size: ButtonSizes
  buttonstyle: ButtonStyles
  className?: string
  onClick?: (event: ReleasedEvent) => void
}

type ReleasedEvent = MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>

export const Button: React.FC<ButtonProps> = ({
  children,
  size,
  buttonstyle,
  className = '',
  onClick,
}) => {
  const handleClick = (event: ReleasedEvent) => typeof onClick === 'function' && onClick(event)

  return (
    <button
      type="submit"
      className={`
      ${className}
      ${buttonsizes[size] || buttonsizes.default} 
      ${styles[buttonstyle] || styles.default} 
      font-heading inline-flex items-center transition ease-in-out duration-150
    `}
      onClick={handleClick}>
      {children}
    </button>
  )
}
