import * as React from 'react'
import { Field } from 'react-final-form'
import Wizard from './wizzardinio'
import { Icon } from '../icons/icon'
import axios from 'axios'

const required = (value) => (value ? undefined : 'Pflichtfeld - bitte ausfüllen')

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const onSubmit = async (values) => {
  axios({
    method: 'POST',
    url: 'https://europe-west6-balmy-parser-288018.cloudfunctions.net/contact-form-open',
    data: values,
    header: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(() => {
      alert(
        'Vielen Dank! Wir haben ihre Anfrage erhalten. Wir werden uns zeitnah bei Ihnen melden.'
      )
    })
    .catch((error) => {
      alert('Leider ist uns ein Fehler unterlaufen, bitte senden Sie das Formular erneut ab')
    })
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'complete_registration',
  })
}

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <div className="mt-4">
          <span className="text-red-400 p-2 bg-white">{error}</span>
        </div>
      ) : null
    }
  />
)

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

const CheckboxGroup = ({ options, name, iconName }) => (
  <div className="grid grid-cols-1 gap-4">
    {options.map((option) => (
      <label>
        <div className="bg-white hover:bg-gray-200 p-4 overflow-hidden shadow-xl">
          <div className="flex items-center">
            <Field
              name={name}
              className="form-checkbox mr-2 h-8 w-8 border-2 border-black checked:bg-black"
              component="input"
              type="checkbox"
              value={option.value}
            />
            <Icon name={option.iconName} className="flex-none w-12 h-12 mx-4" />
            {option.label}
          </div>
        </div>
      </label>
    ))}
  </div>
)

const veredelungen = [
  { value: 'beratung', label: 'Beratung für Neueinkleidung', iconName: 'tshirt' },
  {
    value: 'print',
    label: 'Preiswertes Angebot zu Produkten (wie Arbeitsjacke)',
    iconName: 'discount',
  },
  {
    value: 'ci',
    label: 'Arbeitsbekleidung in der eigenen Corporate Identity',
    iconName: 'corporateidentity',
  },
]

const StepVeredelung = () => (
  <>
    <div>
      <label className="title2 text-white">Was suchen Sie?</label>
      <div className="p-b-sm-fluid" />
      <CheckboxGroup name="vereledungen" options={veredelungen} />
      <Error name="vereledungen" />
    </div>
  </>
)

const ProductGroup = ({ options, name, iconName }) => (
  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
    {options.map((option) => (
      <label className="flex items-stretch">
        <div className="flex-grow bg-white hover:bg-gray-200 p-6 overflow-hidden shadow-xl">
          <div className="flex items-center justify-between mb-6">
            <Icon name={option.iconName} className="flex-none w-12 h-12" />
            <Field
              name={name}
              className="form-checkbox h-8 w-8 border-2 border-black checked:bg-black"
              component="input"
              type="checkbox"
              value={option.value}
            />
          </div>
          <p>{option.label}</p>
        </div>
      </label>
    ))}
  </div>
)

const produktkategorien = [
  {
    value: 'shirt',
    label: 'T-Shirts, Poloshirts',
    iconName: 'tshirt',
  },
  { value: 'sweatshirt', label: 'Sweatshirts', iconName: 'sweatshirt' },
  { value: 'softshell', label: 'Softshell-Jacken', iconName: 'jacke' },
  { value: 'arbeitsjacke', label: 'Arbeitsjacken', iconName: 'workwear' },
  {
    value: 'hose',
    label: 'Arbeitshosen',
    iconName: 'pants',
  },
  { value: 'unklar', label: 'Noch unklar', iconName: 'unklar' },
]

const StepProduktgruppe = () => (
  <>
    <div>
      <label className="title2 text-white">Welche Produkte suchen Sie?</label>
      <div className="p-b-sm-fluid" />
      <ProductGroup name="produktkategorien" options={produktkategorien} />
      <Error name="produktkategorien" />
    </div>
  </>
)

const veredelungen2 = [
  { value: 'print', label: 'Textildruck', iconName: 'druck' },
  { value: 'stick', label: 'Stickerei', iconName: 'stickerei' },
  { value: 'unklar', label: 'Noch unklar', iconName: 'unklar' },
]

const StepVeredelung2 = () => (
  <>
    <div>
      <label className="title2 text-white">Welche Textilveredelung wünschen Sie?</label>
      <div className="p-b-sm-fluid" />
      <CheckboxGroup name="vereledungen2" options={veredelungen2} />
      <Error name="vereledungen2" />
    </div>
  </>
)

const mitarbeiter = [
  { value: '1-5', label: '1 bis 5 Mitarbeiter' },
  { value: '5-10', label: '5 bis 10 Mitarbeiter' },
  { value: '10-20', label: '10 bis 20 Mitarbeiter' },
  { value: '>20', label: '>20 Mitarbeiter' },
]

const SelectorGroup = ({ options, name }) => (
  <div className="grid grid-cols-1 gap-4 my-4">
    {options.map((option) => (
      <label>
        <Field name={name} component="input" type="radio" value={option.value} /> {option.label}
      </label>
    ))}
  </div>
)

const StepMitarbeiter = () => (
  <>
    <label className="title2 text-white">Details zur Anfrage</label>
    <div className="p-b-sm-fluid" />
    <div className="bg-white p-4 overflow-hidden shadow-xl">
      <label className="font-bold mb-4">Wie viele Mitarbeiter werden ausgestattet?</label>
      <div className="m-4">
        <SelectorGroup name="mitarbeiter" options={mitarbeiter} />
      </div>
      <Error name="mitarbeiter" />
      <div className="mt-12" />
      <div className="my-4">
        <label className="font-bold">Für welche Firma ist die Anfrage?</label>
      </div>
      <div className="flex items-center">
        <label className="block text-sm font-medium leading-5 text-gray-700 mr-16">
          Firmenname
        </label>
        <Field
          name="firma"
          component="input"
          type="text"
          placeholder="Name der Firma"
          className="form-input block w-full md:w-72 sm:text-sm sm:leading-5 "
          validate={required}
        />
      </div>

      <Error name="firma" />
    </div>
  </>
)

const StepKontaktetails = () => (
  <>
    <div>
      <h1 className="title2 text-white">Kontaktinformationen</h1>
      <div className="p-b-sm-fluid" />
      <div className="bg-white p-4 overflow-hidden shadow-xl">
        <label className="font-bold mb-4">Wie möchten Sie kontaktiert werden?</label>
        <div className="grid grid-cols-1 gap-4 m-4">
          <label>
            <Field name="kontaktpräferenz" component="input" type="radio" value="mail" /> per Mail
          </label>
          <label>
            <Field name="kontaktpräferenz" component="input" type="radio" value="phone" /> per Phone
          </label>
        </div>

        <p className="font-bold mt-8 mb-4">Kontakdetails</p>
        <Condition when="kontaktpräferenz" is="mail">
          <div className="flex items-center">
            <label className="block text-sm font-medium leading-5 text-gray-700 mr-16">Email</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="Email"
                className="form-input block w-full md:w-72 sm:text-sm sm:leading-5 "
              />
              <Error name="email" />
            </div>
          </div>
        </Condition>
        <Condition when="kontaktpräferenz" is="phone">
          <div className="flex items-center">
            <label className="block text-sm font-medium leading-5 text-gray-700 mr-16">
              Telefon
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <Field
                name="phone"
                component="input"
                type="text"
                placeholder="Telefonnummer (+41 71 788 80 67)"
                className="form-input block w-full md:w-72 sm:text-sm sm:leading-5"
              />
              <Error name="phone" />
            </div>
          </div>
        </Condition>
      </div>
    </div>
  </>
)

export const WizzardSteps = () => (
  <Wizard initialValues={{ wizzard: 'v1' }} onSubmit={onSubmit}>
    <Wizard.Page
      validate={(values) => {
        const errors = {}
        if (!values.vereledungen) {
          errors.vereledungen = 'Bitte 1 Feld auswählen'
        } else if (values.vereledungen.length < 1) {
          errors.vereledungen = 'Bitte mindestens eine Veredelung auswählen'
        }
        return errors
      }}>
      <StepVeredelung />
    </Wizard.Page>
    <Wizard.Page
      validate={(values) => {
        const errors = {}
        if (!values.produktkategorien) {
          errors.produktkategorien = 'Bitte mind. 1 Produkt auswählen'
        } else if (values.produktkategorien.length < 1) {
          errors.produktkategorien = 'Wählen Sie mindestens ein Produkt aus'
        }
        return errors
      }}>
      <StepProduktgruppe />
    </Wizard.Page>
    <Wizard.Page>
      <StepVeredelung2 />
    </Wizard.Page>
    <Wizard.Page
      validate={(values) => {
        const errors = {}
        if (!values.mitarbeiter) {
          errors.mitarbeiter = 'Wie viele Mitarbeiter wollen Sie einkleiden?'
        }
        if (!values.firma) {
          errors.firma = 'Wie lautet ihre Firma?'
        }
        return errors
      }}>
      <StepMitarbeiter />
    </Wizard.Page>
    <Wizard.Page
      validate={(values) => {
        const errors = {}
        if (values.kontaktpräferenz === 'mail') {
          if (!values.email) {
            errors.email = 'Die E-Mail wird für die Kontaktaufnahme benötigt'
          }
        } else if (values.kontaktpräferenz === 'phone') {
          if (!values.phone) {
            errors.phone = 'Die Telefonnummer wird für die Kontaktaufnahme benötigt'
          }
        }
        return errors
      }}>
      <StepKontaktetails />
    </Wizard.Page>
  </Wizard>
)
